import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setRem } from '@/utils/px2rem'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios';

// 设置axios默认的 baseURL
// axios.defaults.baseURL = '/api';//本地代理环境
// axios.defaults.baseURL = 'http://ltbtest.ydinc.net';//测试
//  axios.defaults.baseURL = 'https://test.yue-bei.cn/';//线上
axios.defaults.baseURL = 'https://ltb.ydinc.net/';//正式

// 添加一个请求拦截器
axios.interceptors.request.use(config => {
  // 对每个请求做一些预处理，例如添加认证令牌
  // 确保config.headers存在并是一个对象
  // if (!config.headers || typeof config.headers !== 'object') {
  //   config.headers = {};
  // }
  // config.headers['token'] = '256403a8327ce62134d893781cd1adfd';
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  return config;
});

// 将axios绑定到Vue原型上，这样在任何Vue实例中都能通过this.$http访问
Vue.prototype.$http = axios;

// 然后在组件中你可以这样使用：
// export default {
//   // ...
//   methods: {
//     getData() {
//       this.$http.get('/endpoint').then(/* ... */);
//     }
//   }
// };

setRem()


Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
